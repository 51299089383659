import React, { memo, useContext } from 'react';
import { string } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { AppContext } from '@thd-nucleus/app-render';
import { useLocation, useParams } from '@thd-olt-component-react/router';

import { previewDataModel } from '../../contentful/dataModel/previewDataModel';
import { EducationalLayoutRenderer } from '../../contentful/templates/EducationalLayoutRenderer';
import { NavigationalLayoutRenderer } from '../../contentful/templates/NavigationalLayoutRenderer';
import { InspirationalLayoutRenderer } from '../../contentful/templates/InspirationalLayoutRenderer';
import { FlexibleLayoutRenderer } from '../../contentful/templates/FlexibleLayoutRenderer';

export const LayoutContainer = memo(({ ...props }) => {
  const { instance } = useContext(AppContext);
  let customerType = instance?.customer?.type;

  const { search: querystring, pathname } = useLocation();
  const pathWithQueryParams = `${pathname}${querystring}`;
  const isPreviewLink = !!instance?.headers?.['x-content-preview']
    || pathWithQueryParams.indexOf('render*') > -1
    || pathWithQueryParams.indexOf('contentPV') > -1
    || pathWithQueryParams.indexOf('bassPV') > -1;

  const { LayoutId, LayoutClass, TestPageId } = useParams();

  let layoutQueryName = '';
  let Renderer = ';';

  switch (LayoutClass) {
  case 'EducationalLayout':
    layoutQueryName = 'educationalPreview';
    Renderer = EducationalLayoutRenderer;
    break;
  case 'NavigationalLayout':
    layoutQueryName = 'navigationalPreview';
    Renderer = NavigationalLayoutRenderer;
    break;
  case 'InspirationalLayout':
    layoutQueryName = 'inspirationalPreview';
    Renderer = InspirationalLayoutRenderer;
    break;
  default:
    layoutQueryName = 'flexiblePreview';
    Renderer = FlexibleLayoutRenderer;
    break;
  }

  // This is specific for being able to render pages for Contentful's previews because the page IDs in Contentful will
  // consist of n-value and the '-b2c'/'-b2b' suffix, which are used in the preview url instead of just the n-value
  // like in a production url.
  // if (isPreviewLink && (pageId.endsWith('-b2c') || pageId.endsWith('-b2b'))) {
  //   customerType = pageId.slice(-3);
  //   pageId = pageId.replace(/-b2[b|c]$/, '');
  // }

  const { data, loading, error } = useDataModel(layoutQueryName, {
    variables: {
      id: LayoutId,
      layoutClass: LayoutClass,
    },
  });

  // useHelmet('landing-page', { data }, imagePreloader, [data]);

  if (!data || loading || error) {
    return null;
  }

  const fakeLayoutsData = {
    layouts: {
      title: 'Layout Preview',
      content: data?.layout
    }
  };

  return (
    <Renderer
      data={fakeLayoutsData}
      loading={loading}
      pageId={TestPageId}
      storeId={props?.storeId}
      customerType={customerType}
      pageTypeComponents={{
        landingPageBreadcrumbs: null,
        landingPageMetadata: null
      }}
      isPreviewLink={isPreviewLink}
    />
  );
});

LayoutContainer.dataModel = previewDataModel;

LayoutContainer.displayName = 'LayoutContainer';

LayoutContainer.defaultProps = {};

LayoutContainer.propTypes = {
  storeId: string.isRequired,
};
