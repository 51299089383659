import React, { memo } from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';
import { LayoutContainer } from './containers/LayoutContainer';

export const LayoutPreview = memo(() => {
  const storeId = useStoreId();

  const defaultVariables = {
    keyword: null,
    layoutClass: 'EducationalLayout',
    storeId
  };

  return (
    <QueryProvider cacheKey="landing-page-test" defaultVariables={defaultVariables}>
      <LayoutContainer storeId={storeId} />
    </QueryProvider>
  );
});
